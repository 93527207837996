import type { RefObject } from 'react'
import { useEffect } from 'react'

/**
 * DO NOT USE THIS HOOK IF YOU CAN AVOID IT AS IT ATTACHES
 * THIS IS ONLY AS LAST RESORT AND SHOULD BE CONDITONALLY
 * ATTACHED TO WHATEVER IS VISIBLE / RENDERED
 */
export function useHandleClickOutside(
  ref: RefObject<HTMLElement>,
  onClickOutside: () => void,
  trigger: 'click' | 'mouseup' = 'mouseup',
  /**
   * do not attach event handlers if they're not
   * being used at the time
   * this is useful for components that are conditionally
   * rendered or visible which is where this is used
   * most
   *
   * @default false
   * @reason the developer should be explicit about this
   * and really try to conditionally attach these
   */
  shouldListen?: boolean
) {
  useEffect(() => {
    if (shouldListen === true) {
      function handleClickOutside(e: MouseEvent | TouchEvent) {
        const isClickOutsideCombobox =
          e.target instanceof Node &&
          !(ref.current?.contains(e.target) || e.target === ref.current)

        if (isClickOutsideCombobox) {
          onClickOutside()
        }
      }

      if (trigger === 'mouseup') {
        // Generally prefer mouseup / touchend events since Headless UI
        // popover elements prevent click events from firing

        window.addEventListener('mouseup', handleClickOutside, {
          passive: true,
        })
        window.addEventListener('touchend', handleClickOutside, {
          passive: true,
        })
      } else {
        window.addEventListener('click', handleClickOutside)
      }

      return () => {
        if (trigger === 'mouseup') {
          window.removeEventListener('mouseup', handleClickOutside)
          window.removeEventListener('touchend', handleClickOutside)
        } else {
          window.removeEventListener('click', handleClickOutside)
        }
      }
    }
  }, [onClickOutside, ref, trigger, shouldListen])
}
